
import { defineComponent, reactive, ref, computed, getCurrentInstance } from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { parseNumberOrZero } from '@/utils/illuminate';
import { RuleObject } from 'ant-design-vue/es/form/interface';

import { createApiUrl, firstError, toApiUnit } from '@/utils/utils';
import { request } from '@/utils/request';
import { useEcho } from '@/utils/echo';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { useScene, stepSupplementItemType } from './utils';

export default defineComponent({
  name: 'SceneDetailDrawer',
  components: {
    MinusCircleOutlined,
    PlusOutlined,
  },
  emits: ['complete'],
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    const sceneState = useScene();
    const echo = useEcho();

    const visible = ref(false);
    const actionType = ref<string>('create');
    const editBlock = ref<any>(null);
    const editBlockState = computed(() => {
      const isCreate = actionType.value === 'create';
      return {
        title: isCreate || editBlock.value === 'title',
        oil: isCreate || editBlock.value === 'oil',
        pay: isCreate || editBlock.value === 'pay',
        claim: isCreate || editBlock.value === 'claim',
      };
    });

    const formData = reactive({
      ...sceneState.getDefaultRecordData(),
    });

    const state = computed<boolean>({
      get() {
        return !!formData.state;
      },
      set(val) {
        formData.state = +val;
      },
    });

    // = ---------------------------- = 新建/显示入口 = ---------------------------- =
    let recordId: number | null = null;
    let oldSceneName = '';
    const editorForm = ref(null as any);
    echo('doWith', async ({ action, id }: { action: string, id: number }) => {
      if (action === 'create') instance?.$sensors.track('es_saas_system_car_new_popup_view', { page_name: '制度管理_车辆制度列表页_新建制度_浏览' });
      actionType.value = action;
      visible.value = true;
      Object.assign(formData, sceneState.getDefaultRecordData());
      if (formData.id) delete formData.id;
      recordId = null;

      if (action === 'view') {
        recordId = id;
        editorForm.value?.clearValidate();
        await sceneState.loadSceneData(recordId);
        console.log(sceneState.sceneData, 'sceneData');
        Object.assign(formData, sceneState.sceneData);
        console.log(formData);
        oldSceneName = sceneState.sceneData.title;
      }
    });

    // = ---------------------------- = 启用/取消编辑 = ---------------------------- =
    const hasFormEditing = computed(() => (editBlock.value || actionType.value === 'create'));
    const onSwitchEdit = (block: string | null) => {
      editBlock.value = block;
      if (!block) Object.assign(formData, sceneState.sceneData);
    };
    const title = computed(() => (actionType.value === 'create'
      ? '新建制度'
      : hasFormEditing.value
        ? '编辑制度' : '查看制度详情'));

    // = ---------------------------- = 表单提交 = ---------------------------- =
    const submiting = ref(false);

    // const validateNotQqual = async (rule: RuleObject, value: string) => {
    //   if (value === '') {
    //     return Promise.reject('Please input the password');
    //   } else {

    //   }
    // };
    const validateCustomRuleYn = async (rule: RuleObject, value: number) => {
      if (value && (!formData.customTitle || !formData.customContent)) {
        return Promise.reject('请填写自定义规则标题和内容');
      }
      return Promise.resolve();
    };
    const rules = computed(() => {
      const validateBiggerThenZero = (message: string) => (rule: any, value: any) => {
        if (parseNumberOrZero(value) > 0) return Promise.resolve();
        return Promise.reject(message);
      };
      const validateNotQqual = (message: string) => (rule: any, value: any) => {
        if (editBlockState.value.claim && state.value) { // 展示油补管理 并且 是可编辑状态
          return parseNumberOrZero(value) ? Promise.resolve() : Promise.reject(message);
        }
        return Promise.resolve();
      };
      
      const basic = {
        title: [
          { required: true, message: '请输入制度名', trigger: 'blur' },
          { validator: (rule: any, value: any) => (value.length > 8 ? Promise.reject('最多可输入8个字符') : Promise.resolve()) },
          { // 验重
            validator(rule: any, value: any) {
              if (oldSceneName === value) return Promise.resolve();
              return new Promise<void>((resolve, reject) => {
                request.get(createApiUrl('/newlinkSass/vehicle/restrict/checkVehicleQuotaRuleName'), {
                  params: { ruleName: value, enterpriseId: formData.enterpriseId, id: recordId },
                })
                  .then(res => (res.data ? resolve() : reject('制度已存在')))
                  .catch(e => reject(e.message));
              });
            },
            trigger: 'blur',
          },
        ],
      };
      if (store.getters['User/isScheduleModel']) {
        // 油补模式企业 报销上限管理
        Object.assign(basic, {
          quota: [
            { required: true, message: '请输入限制金额', trigger: 'blur' },
            { validator: validateBiggerThenZero('限制金额必须大于 0'), trigger: 'blur' },
          ],
        });
      } 
      if (!store.getters['User/isScheduleModel'] && store.getters['User/oilSubsidyType'] === 2 && editBlockState.value.claim) {
        // 私车公用油补管理
        Object.assign(basic, {
          perKilometerAmount: [
            { validator: validateNotQqual('油补标准必须大于 0'), trigger: 'blur' },
          ],
          radius: [
            { validator: validateBiggerThenZero('打卡半径必须大于 0'), trigger: 'blur' },
          ],
          updateAddressYn: [
            { required: true, type: 'number', message: '修改地址必选', trigger: 'blur' },
          ],
          supplementPassPointYn: [
            { required: true, type: 'number', message: '补充途径地必选', trigger: 'blur' },
          ],
          // passPointConf: [
          //   { required: state.value, type: 'number', message: '请输入打卡半径', trigger: 'blur' },
          // ],
          supplementLimitAmount: [
            { validator: validateBiggerThenZero('限制金额必须大于 0'), trigger: 'blur' },
          ],
          approveType: [
            { required: true, type: 'number', message: '审批方式必选', trigger: 'blur' },
          ],
          customRuleYn: [
            { required: true, type: 'number', message: '自定义规则必选', trigger: 'blur' },
            { validator: validateCustomRuleYn, trigger: 'blur' },
          ],
          oilSubsidyOfflineFlag: [
            { required: true, type: 'number', message: '请选择线下加油报销', trigger: 'blur' },
          ],
        });
      }
      return basic;
    });

    const resetFields = () => {
      Object.assign(sceneState.sceneData, { ...sceneState.getDefaultRecordData() });
    };

    const onClearError = (field: string) => {
      if (field) return editorForm.value.clearValidate(field);
      return editorForm.value.clearValidate();
    };

    // = ---------------------------- = 提交表单 = ---------------------------- =
    const onSubmit = async () => {
      if (submiting.value) return;

      try {
        await editorForm.value.validate();
      } catch (e: Error & any) {
        return message.error(firstError(e));
      }

      submiting.value = true;
      const { stepSupplementLimits } = formData;
      const data = {
        ...formData,
        supplementLimit: toApiUnit(parseNumberOrZero(formData.supplementLimitAmount)),
        supplementLimitAmount: toApiUnit(parseNumberOrZero(formData.supplementLimitAmount)),
        perKilometerAmount: toApiUnit(parseNumberOrZero(formData.perKilometerAmount)),
        radius: parseNumberOrZero(formData.radius),
        stepSupplementLimits: (stepSupplementLimits || []).map(item => {
          const { startKm, endKm, supplementLimitAmount } = item;
          // 需要转化为分，展示元，存储分
          return { startKm, endKm, supplementLimitAmount: toApiUnit(parseNumberOrZero(supplementLimitAmount)) };
        }),
      };

      let res: any[];
      try {
        res = await request.post(createApiUrl('/newlinkSass/vehicle/restrict/add-or-update'), data);
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        submiting.value = false;
      }

      if (actionType.value === 'create') {
        message.success('新建制度完成!');
        visible.value = false;
        ctx.emit('complete');
      } else {
        message.success('制度保存完成!');
        ctx.emit('complete');
        editorForm.value?.clearValidate();
        oldSceneName = formData.title;
        editBlock.value = null;
      }
    };

    const onCancel = () => {
      visible.value = false;
      editBlock.value = null;
    };

    const validateTieredPrice = (item:Record<string, any>, index:number, arr: stepSupplementItemType[], rule:any, value:any) => {
      if (!item.supplementLimitAmount) return Promise.reject('请输入金额');
      return Promise.resolve(); 
    };

    const validateTieredDistance = (item:Record<string, any>, index:number, arr: stepSupplementItemType[], rule:any, value:any) => {
      if (!item.endKm) return Promise.reject('请输入距离'); 
      if (index === 0 && item.endKm <= 0) return Promise.reject('需大于0');
      if (index > 0 && +item.endKm <= +arr[index - 1].endKm) return Promise.reject('小于起始距离');
      return Promise.resolve(); 
    };
    const changeEndKm = () => {
      const { length } = formData.stepSupplementLimits;
      for (let i = 1; i < length; i++) {
        formData.stepSupplementLimits[i].startKm = formData.stepSupplementLimits[i - 1].endKm;
      }
    };
    const removeDomain = (index:number) => {
      formData.stepSupplementLimits.splice(index, 1);
      changeEndKm();
    };
    const addDomain = (domain: stepSupplementItemType, index:number) => {
      if (!(domain.endKm && domain.supplementLimitAmount)) return message.warning(`请先填写阶梯${index + 1}信息`);
      if (formData.stepSupplementLimits.length >= 10) return message.warning('不能超过10个!');
      formData.stepSupplementLimits.splice(index + 1, 0, {
        key: Date.now(),
        startKm: '',
        endKm: '',
        supplementLimitAmount: '',
      });
    };

    return {
      ...sceneState,

      labelCol: { span: 5 },
      wrapperCol: { span: 18, offset: 1 },

      visible,
      actionType,
      editBlock,
      editBlockState,

      // doWith,
      onCancel,

      formData,
      state,
      rules,
      resetFields,
      editorForm,
      onClearError,
      onSubmit,
      submiting,

      hasFormEditing,
      title,
      onSwitchEdit,

      validateTieredPrice,
      validateTieredDistance,
      addDomain,
      removeDomain,
      changeEndKm,
    };
  },
});
