import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "autoStaffList__content" }
const _hoisted_2 = { class: "fl-left" }
const _hoisted_3 = { class: "fl-right" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_add_car = _resolveComponent("add-car")!
  const _component_remove_staff_modal = _resolveComponent("remove-staff-modal")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer autoStaffList" }, {
    title: _withCtx(() => [
      _createVNode(_component_router_link, {
        class: "link-goback",
        to: {name: 'scene.oil.list'}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_app_icon, { type: "arrow-left" })
        ]),
        _: 1
      }, 8, ["to"]),
      _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form, {
          class: "fl-sb autoStaffList__filterForm",
          form: _ctx.filterParams,
          layout: 'inline'
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _ctx.onAddItem
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("添加车辆")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  (!_ctx.sceneData.defaultFlag)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 0,
                        type: "primary",
                        onClick: _ctx.onRemoveMultipleItem
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("批量解绑")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_a_form_item, { label: "车辆" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.filterParams.nameOrLicensePlateLike,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterParams.nameOrLicensePlateLike) = $event)),
                    placeholder: "车辆名称 / 车牌号码",
                    "allow-clear": ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    ghost: "",
                    onClick: _ctx.onRefreshTable
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("查询")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["form"]),
        (_ctx.sceneData?.title === '默认制度')
          ? (_openBlock(), _createBlock(_component_app_table, {
              key: 0,
              ref: "tableRef",
              size: "default",
              "row-key": "relId",
              "show-pagination": true,
              columns: _ctx.tableColumns,
              data: _ctx.loadTableData
            }, {
              action: _withCtx(({ record }) => [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.onDelItem(record)), ["prevent"])
                }, "解绑车辆", 8, _hoisted_4)
              ]),
              _: 1
            }, 8, ["columns", "data"]))
          : (_openBlock(), _createBlock(_component_app_table, {
              key: 1,
              ref: "tableRef",
              size: "default",
              "row-key": "relId",
              "show-pagination": true,
              columns: _ctx.tableColumns,
              data: _ctx.loadTableData,
              alert: _ctx.tableOptions.alert,
              "row-selection": _ctx.tableOptions.rowSelection
            }, {
              action: _withCtx(({ record }) => [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.onDelItem(record)), ["prevent"])
                }, "解绑车辆", 8, _hoisted_5)
              ]),
              _: 1
            }, 8, ["columns", "data", "alert", "row-selection"]))
      ]),
      _createVNode(_component_add_car, {
        ref: "addItemRef",
        "load-staff-list": _ctx.onLoadCarList,
        "submit-user-list": _ctx.onSubmitStaffList,
        onComplete: _ctx.onRefreshTable
      }, null, 8, ["load-staff-list", "submit-user-list", "onComplete"]),
      _createVNode(_component_remove_staff_modal, {
        ref: "removeMultipleItemRef",
        onComplete: _ctx.onRefreshTable
      }, null, 8, ["onComplete"])
    ]),
    _: 1
  }))
}