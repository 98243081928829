import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_staff_list = _resolveComponent("staff-list")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_carList = _resolveComponent("carList")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer scene--list" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
        class: "card_tab"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: 1,
            tab: "员工制度列表"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_staff_list)
            ]),
            _: 1
          })),
          (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: 2,
            tab: "车辆制度列表"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_carList)
            ]),
            _: 1
          }))
        ]),
        _: 1
      }, 8, ["activeKey"])
    ]),
    _: 1
  }))
}