
import { defineComponent, ref, computed, watch, PropType, Ref } from 'vue';
import { message } from 'ant-design-vue';
import { CloseOutlined } from '@ant-design/icons-vue';

import { useState } from '@/store';
import VirtualList from '@/components/VirtualList/VirtualList';

type StaffType = {
  departmentId: any;
  phone: string;
  id: string;
  licensePlate: string;
  vin: string;
}

type StaffItemExtraProps = {
  isMultiple: Ref<boolean>;
  selectStaffIds: Ref<string[]>;
  onStaffStateChange: (id: string, state: boolean) => void;
}

const StaffItem = defineComponent({
  name: 'CarItem',
  props: {
    source: { type: Object as PropType<StaffType>, required: true },
    isMultiple: { type: Object as PropType<Ref<boolean>>, required: true },
    selectStaffIds: { type: Object as PropType<StaffItemExtraProps['selectStaffIds']>, required: true },
    onStaffStateChange: { type: Function as PropType<StaffItemExtraProps['onStaffStateChange']>, required: true },
  },
  setup(props) {
    const isChecked = computed(() => props.selectStaffIds.value.includes(props.source.id));
    const onItemChange = () => {
      props.onStaffStateChange(props.source.id, !isChecked.value);
    };

    const renderStaffInfo = () => (<p class="staffInfo" title={`${props.source.licensePlate} - ${props.source.vin}`}>
      <span>{props.source.licensePlate}</span>
      <span>- [{props.source.vin}]</span>
    </p>);

    return () => (props.isMultiple.value
      ? <a-checkbox checked={isChecked.value} onChange={onItemChange}>{renderStaffInfo()}</a-checkbox>
      : <a-radio checked={isChecked.value} onClick={onItemChange}>{renderStaffInfo()}</a-radio>);
  },
});

const SelectStaffItem = defineComponent({
  name: 'SelectCarItem',
  props: {
    source: { type: Object as PropType<StaffType>, required: true },
    onRemoveSelectStaff: { type: Function as PropType<((id: string) => void)>, required: true },
  },
  setup(props) {
    return () => <div>
      <span class="name" title={props.source.licensePlate}>{props.source.licensePlate}</span>
      <a-button onClick={() => props.onRemoveSelectStaff(props.source.id)}><CloseOutlined /></a-button>
    </div>;
  },
});

export default defineComponent({
  name: 'AddCarModal',
  components: {
    VirtualList,
  },
  props: {
    mode: { type: String, default: 'multiple' },
    tips: { type: String, default: '' },
    help: { type: String, default: '' },
    loadStaffList: { type: Function as PropType<(deptId: string | number) => Promise<any>>, required: true },
    submitUserList: { type: Function as PropType<(userCodes: string[]) => Promise<any>>, required: true },
  },
  emits: ['complete'],
  setup(props, ctx) {
    const state = useState();
    const staffKeywords = ref('');
    const isMultiple = computed(() => props.mode === 'multiple');

    // = ---------------------------- = 员工列表 = ---------------------------- =
    const staffList = ref<StaffType[]>([]);
    const staffLoading = ref(false);

    const filterStaffList = computed(() => {
      if (!staffKeywords.value) return staffList.value;
      return staffList.value.filter(item => (item.licensePlate.indexOf(staffKeywords.value) !== -1));
    });

    // 加载数据
    const loadData = async () => {
      staffLoading.value = true;
      try {
        staffList.value = await props.loadStaffList('');
        console.log(staffList.value);
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        setTimeout(() => {
          staffLoading.value = false;
        }, 500);
      }
    };

    /* = ---------------------------- = 选中员工 = ---------------------------- = */
    const selectStaffIds = ref<string[]>([]);
    const allSelectStaffIdSet = new Set<string>();
    const allSeletctStaff = ref<StaffType[]>([]);

    watch(filterStaffList, val => {
      selectStaffIds.value = val.filter(i => allSelectStaffIdSet.has(i.id)).map(i => i.id);
    });

    const resetAllSelectStaff = () => {
      allSeletctStaff.value = staffList.value.filter(i => allSelectStaffIdSet.has(i.id));
    };

    const onStaffStateChange = (id: string, state: boolean) => {
      if (state && isMultiple.value) {
        allSelectStaffIdSet.add(id);
        selectStaffIds.value.push(id);
      } else if (state && !isMultiple.value) {
        allSelectStaffIdSet.clear();
        allSelectStaffIdSet.add(id);
        selectStaffIds.value = [id];
      } else {
        allSelectStaffIdSet.delete(id);
        selectStaffIds.value = selectStaffIds.value.filter(i => i !== id);
      }

      resetAllSelectStaff();
    };

    const itemExtraProps: StaffItemExtraProps = {
      isMultiple,
      selectStaffIds,
      onStaffStateChange,
    };

    const isPartSelect = computed(() => selectStaffIds.value.length > 0 && selectStaffIds.value.length < filterStaffList.value.length);

    const onChangeSelectAll = () => {
      if (selectStaffIds.value.length < filterStaffList.value.length) {
        selectStaffIds.value = filterStaffList.value.map(item => {
          allSelectStaffIdSet.add(item.id);
          return item.id;
        });
      } else {
        selectStaffIds.value.map(allSelectStaffIdSet.delete.bind(allSelectStaffIdSet));
        selectStaffIds.value = [];
      }

      resetAllSelectStaff();
    };

    const onRemoveSelectStaff = (id: string) => {
      allSelectStaffIdSet.delete(id);
      allSeletctStaff.value = allSeletctStaff.value.filter(i => i.id !== id);
      selectStaffIds.value = selectStaffIds.value.filter(i => i !== id);
    };

    // = ---------------------------- = 显示入口 = ---------------------------- =
    const visible = ref<boolean>(false);
    const add = () => {
      staffList.value = [];
      allSelectStaffIdSet.clear();
      selectStaffIds.value = [];
      allSeletctStaff.value = [];
      visible.value = true;
      loadData();
    };

    // = ---------------------------- = 提交数据 = ---------------------------- =
    const clean = () => {
      staffList.value = [];
      staffKeywords.value = '';
    };

    const onSubmit = async () => {
      if (allSelectStaffIdSet.size) {
        try {
          await props.submitUserList(Array.from(allSelectStaffIdSet));
        } catch (e: Error & any) {
          return message.error(e.message);
        }
      }

      if (allSelectStaffIdSet.size) message.success('车辆添加成功');

      visible.value = false;
      clean();

      ctx.emit('complete');
    };

    return {
      staffItemComponent: StaffItem,
      selectStaffComponent: SelectStaffItem,
      visible,
      add,
      isMultiple,

      staffKeywords,
      selectStaffIds,
      filterStaffList,
      staffLoading,
      itemExtraProps,
      isPartSelect,

      allSeletctStaff,
      onChangeSelectAll,
      onRemoveSelectStaff,

      onSubmit,
      onCancel: clean,
    };
  },
});
