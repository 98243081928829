
import { defineComponent, ref } from 'vue';
import staffList from './List.vue';
import carList from './CarList.vue';

export default defineComponent({
  components: {
    staffList,
    carList,
  },
  setup() {
    return {
      activeKey: ref(1),
    };
  },
});
