
import { defineComponent, ref, reactive, computed, createVNode, getCurrentInstance, onMounted } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { useRouter } from 'vue-router';

import { useStore } from 'vuex';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import type { TableColumn } from '@/components/Table';

import { useCurrentEnterpriseHook } from '@/store';
import DeleteConfirm from './carComponents/DeleteConfirm.vue';
import ActionDrawer from './carComponents/ActionDrawer.vue';

import { SceneRecordType } from './carComponents/utils';

export default defineComponent({
  name: 'CarList',
  components: {
    ActionDrawer,
    DeleteConfirm,
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    useCurrentEnterpriseHook(store.state.User.enterprise.enterpriseId);
    const router = useRouter();

    const filterParams = reactive({
      titleLike: '',
    });

    // = ---------------------------- = 加载数据方法 = ---------------------------- =
    const loadTableData = (parameter: any) => new Promise<any>((resolve, reject) => {
      request.post(createApiUrl('/newlinkSass/vehicle/restrict/get-restrict-page'), {
        ...parameter, ...filterParams,
      })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
          resolve({ total: 0, list: [] });
        });
    });

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const tableColumns: TableColumn<SceneRecordType>[] = [
      { title: '制度名称', dataIndex: 'title' },
      { title: '使用车辆', dataIndex: 'carNum', slots: { customRender: 'carNum' } },
      { title: '操作', width: '220px', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    onMounted(() => {
      instance?.$sensors.track('es_saas_system_car_page_view', { page_name: '制度管理_车辆制度列表页_浏览' });
    });

    return {
      filterParams,

      tableColumns,
      loadTableData,

      tableRef,
      onRefreshTable,
    };
  },
});
