import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sceneName" }
const _hoisted_2 = { class: "warning" }
const _hoisted_3 = {
  key: 0,
  class: "tips"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    class: "deleteConfirm sceneDeleteConfirm",
    title: "删除制度",
    "ok-button-props": { prefixCls: 'ant-btn ant-btn-danger' },
    "ok-text": "删除",
    onOk: _ctx.onSubmit
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_1, "制度名称: " + _toDisplayString(_ctx.recordData.title), 1),
        _createElementVNode("p", _hoisted_2, [
          _createVNode(_component_app_icon, {
            type: "question-circle-filled",
            class: "text-warning"
          }),
          _createTextVNode(" 确认删除该制度 ？ ")
        ]),
        (_ctx.recordData.num)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, "该制度下有绑定员工，删除后员工自动绑定默认制度"))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible", "onOk"]))
}