
import { defineComponent, ref, reactive, computed, createVNode, getCurrentInstance, onMounted } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { CheckCircleFilled } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { noEmptyProp } from '@/utils/illuminate';

import { useStore } from 'vuex';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import type { TableColumn } from '@/components/Table';

import { useCurrentEnterpriseHook } from '@/store';
import DeleteConfirm from './parts/DeleteConfirm.vue';
import ActionDrawer from './parts/ActionDrawer.vue';

import { SceneRecordType } from './utils';

export default defineComponent({
  name: 'ReasonListView',
  components: {
    ActionDrawer,
    DeleteConfirm,
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    useCurrentEnterpriseHook(store.state.User.enterprise.enterpriseId);
    const router = useRouter();

    const filterParams = reactive({
      title: '',
      state: '',
    });

    console.log('==================================== 1');
    console.log(store.state.User.currentEnterpriseId);

    // = ---------------------------- = 加载数据方法 = ---------------------------- =
    const loadTableData = (parameter: any) => new Promise<any>((resolve, reject) => {
      request.get(createApiUrl('/newlinkSass/staff/restrict/list'), {
        params: { ...parameter, ...noEmptyProp(filterParams) },
      })
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
          resolve({ total: 0, list: [] });
        });
    });

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const tableColumns: TableColumn<SceneRecordType>[] = [
      { title: '制度名称', dataIndex: 'title' },
      { title: '使用员工', dataIndex: 'num', slots: { customRender: 'num' } },
      { title: '操作', width: '220px', slots: { customRender: 'action' }, fixed: 'right' },
    ];

    // = ---------------------------- = 新增完成 = ---------------------------- =
    const onCreateDone = (no: number) => {
      onRefreshTable();
      Modal.confirm({
        title: '新增制度完成',
        icon: createVNode(CheckCircleFilled, { class: 'text-info' }),
        okText: '绑定员工',
        cancelText: '稍后绑定',
        class: 'noneFooterBorder',
        onOk() {
          router.push({ name: 'scene.staff', params: { id: no } });
        },
      });
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_system_staff_page_view', { page_name: '制度管理_员工制度列表页_浏览' });
    });

    return {
      filterParams,

      tableColumns,
      loadTableData,

      tableRef,
      onRefreshTable,

      onCreateDone,
    };
  },
});
