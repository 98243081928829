
import { defineComponent, reactive, ref, computed, onMounted, getCurrentInstance, watch } from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { RuleObject } from 'ant-design-vue/es/form/interface';
import pick from 'lodash/pick';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { parseNumberOrZero } from '@/utils/illuminate';

import { createApiUrl, firstError, toApiUnit } from '@/utils/utils';
import { request } from '@/utils/request';
import { useEcho } from '@/utils/echo';

import { frequencyTypeMap, useScene, stepSupplementItemType } from '../utils';

export default defineComponent({
  name: 'SceneDetailDrawer',
  components: {
    MinusCircleOutlined,
    PlusOutlined,
  },
  emits: ['complete'],
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    const sceneState = useScene();
    const echo = useEcho();

    const visible = ref(false);
    const actionType = ref<string>('create');
    const editBlock = ref<any>(null);
    const editBlockState = computed(() => {
      const isCreate = actionType.value === 'create';
      return {
        title: isCreate || editBlock.value === 'title',
        oil: isCreate || editBlock.value === 'oil',
        pay: isCreate || editBlock.value === 'pay',
        claim: isCreate || editBlock.value === 'claim',
        business: isCreate || editBlock.value === 'business',
      };
    });
    const oilNosList = reactive<{ qy: Array<{ oilNo: number, oilName: string}>, cy: Array<{ oilNo: number, oilName: string}>, trq: Array<{ oilNo: number, oilName: string}> }>({ qy: [], cy: [], trq: [] });

    const formData = reactive({
      ...sceneState.getDefaultRecordData(),
    });

    const oilSupplementStatus = computed<boolean>({
      get() {
        return !!formData.oilSupplementStatus;
      },
      set(val) {
        formData.oilSupplementStatus = +val;
      },
    });

    const qyChange = (value:any) => {
      const qyNames: Array<any> = [];
      oilNosList.qy.forEach((item: any) => {
        if (value.includes(item.oilNo)) qyNames.push(item.oilName);
      });
      formData.qyNames = qyNames;
    };

    const cyChange = (value: any) => {
      const cyNames: Array<any> = [];
      oilNosList.cy.forEach((item: any) => {
        if (value.includes(item.oilNo)) cyNames.push(item.oilName);
      });
      formData.cyNames = cyNames;
    };

    const trqChange = (value: any) => {
      const trqNames: Array<any> = [];
      oilNosList.trq.forEach((item: any) => {
        if (value.includes(item.oilNo)) trqNames.push(item.oilName);
      });
      formData.trqNames = trqNames;
    };

    const handleProhibit = (data: any) => {
      const { prohibitOilNos, prohibitOilNames } = data;
      const oilNos = prohibitOilNos ? prohibitOilNos.split(',') : []; 
      const oilNames = prohibitOilNames ? prohibitOilNames.split(',') : []; 
      return oilNos.reduce((pre: any, item: any, index: number) => {
        if (!item) return pre;
        const gasFlag = oilNosList.qy.findIndex((flag:any) => flag.oilNo === Number(item));
        const dieselFlag = oilNosList.cy.findIndex((flag:any) => flag.oilNo === Number(item));
        const natgasFlag = oilNosList.trq.findIndex((flag:any) => flag.oilNo === Number(item));
        if (gasFlag !== -1) {
          pre.qyNos.push(Number(item));
          pre.qyNames.push(oilNames[index]);
        }  
        if (dieselFlag !== -1) {
          pre.cyNos.push(Number(item));
          pre.cyNames.push(oilNames[index]);
        }
        if (natgasFlag !== -1) {
          pre.trqNos.push(Number(item));
          pre.trqNames.push(oilNames[index]);
        }
        return pre;
      }, { qyNos: [], qyNames: [], cyNos: [], cyNames: [], trqNos: [], trqNames: [] });
    };

    // = ---------------------------- = 新建/显示入口 = ---------------------------- =
    let recordId = '';
    let oldSceneName = '';
    let recordEnterpriseId = '';
    const editorForm = ref(null as any);
    echo('doWith', async ({ action, id, enterpriseId }: { action: string, id: string, enterpriseId: string }) => {
      if (action === 'create') instance?.$sensors.track('es_saas_system_staff_new_popup_view', { page_name: '制度管理_员工制度列表页_新建制度_浏览' });
      actionType.value = action;
      visible.value = true;
      console.log(sceneState.getDefaultRecordData());
      Object.assign(formData, sceneState.getDefaultRecordData());

      if (action === 'view') {
        recordId = id;
        recordEnterpriseId = enterpriseId;
        editorForm.value?.clearValidate();
        await sceneState.loadSceneData(recordId, enterpriseId);
        console.log(sceneState.sceneData); 
        const prohibitData = handleProhibit(sceneState.sceneData); 
        Object.assign(formData, { ...sceneState.sceneData, ...prohibitData });
        console.log(formData);
        oldSceneName = sceneState.sceneData.title;
      }
    });

    const oilProhibitChange = (e: any) => {
      const { value } = e.target;
      if (!value) {
        editorForm.value.clearValidate('oilProhibitFlag');
        formData.qyNos = [];
        formData.qyNames = [];
        formData.cyNos = [];
        formData.cyNames = [];
        formData.trqNos = [];
        formData.trqNames = [];
      }
    };

    // = ---------------------------- = 启用/取消编辑 = ---------------------------- =
    const hasFormEditing = computed(() => (editBlock.value || actionType.value === 'create'));
    const onSwitchEdit = (block: string | null) => {
      editBlock.value = block;
      if (!block) {
        const prohibitData = handleProhibit(sceneState.sceneData); 
        Object.assign(formData, { ...sceneState.sceneData, ...prohibitData });
        editBlock.value = null;
        editorForm.value.clearValidate();
      }
    };
    const title = computed(() => (actionType.value === 'create'
      ? '新建制度'
      : hasFormEditing.value
        ? '编辑制度' : '查看制度详情'));

    // = ---------------------------- = 显示数据 = ---------------------------- =
    const gasFrequency = computed(() => {
      if (!formData.frequency) return '不限';

      return `${frequencyTypeMap[formData.frequency]} ${formData.frequencyNum} 次`;
    });

    const gasFrequencySuffix = computed(() => `次 / ${frequencyTypeMap[formData.frequency]}`);

    // = ---------------------------- = 表单提交 = ---------------------------- =
    const submiting = ref(false);
    const validateCustomRuleYn = async (rule: RuleObject, value: number) => {
      if (value && (!formData.customTitle || !formData.customContent)) {
        return Promise.reject('请填写自定义规则标题和内容');
      }
      return Promise.resolve();
    };
    const rules = computed(() => {
      const validateBiggerThenZero = (message: string) => (rule: any, value: any) => {
        if (parseNumberOrZero(value) > 0) return Promise.resolve();
        return Promise.reject(message);
      };
      
      const validateNotQqual = (message: string) => (rule: any, value: any) => {
        if (editBlockState.value.claim && oilSupplementStatus.value) { // 展示油补管理 并且 油补管理是可编辑状态 并且 状态开启 并且 内容为真
          return parseNumberOrZero(value) ? Promise.resolve() : Promise.reject(message);
        }
        return Promise.resolve();
      };

      const validateQuota = (rule: any, value: any) => {
        if (!Number(value)) return Promise.reject('请输入限制金额');
        if (parseNumberOrZero(value) > 0) return Promise.resolve();
        return Promise.reject('限制金额必须大于 0');
      };
      
      const basic = {
        title: [
          { required: true, message: '请输入制度名', trigger: 'blur' },
          { validator: (rule: any, value: any) => (value.length > 8 ? Promise.reject('最多可输入8个字符') : Promise.resolve()) },
          { // 验重
            validator(rule: any, value: any) {
              if (oldSceneName === value) return Promise.resolve();
              return new Promise<void>((resolve, reject) => {
                request.get(createApiUrl('/newlinkSass/quota/ar/check/ruleName'), {
                  params: { ruleName: value, enterpriseId: formData.enterpriseId },
                })
                  .then(res => (res.data ? resolve() : reject('制度已存在')))
                  .catch(e => reject(e.message));
              });
            },
            trigger: 'blur',
          },
        ],
        allowSelfApprovalFlag: [
          { required: true, type: 'boolean', message: '请选择是否允许自我审批', trigger: 'blur' },
        ],
      };
      if (store.getters['User/isScheduleModel'] && editBlockState.value.claim) {
        // 油补模式企业 报销上限管理
        Object.assign(basic, {
          totalQuota: [
            { validator: validateQuota, trigger: 'blur' },
          ],
          quota: [
            { validator: validateQuota, trigger: 'blur' },
          ],
          expresswayQuota: [
            { validator: validateBiggerThenZero('限制金额必须大于 0'), trigger: 'blur' },
          ],
        });
      }
      if (!store.getters['User/isScheduleModel'] && store.getters['User/oilSubsidyType'] === 1 && editBlockState.value.claim) {
        // 私车公用油补管理
        Object.assign(basic, {
          perKilometerAmount: [
            { validator: validateNotQqual('油补标准必须大于 0'), trigger: 'blur' },
          ],
          radius: [
            { validator: validateBiggerThenZero('打卡半径必须大于 0'), trigger: 'blur' },
          ],
          updateAddressYn: [
            { required: true, type: 'number', message: '修改地址必选', trigger: 'blur' },
          ],
          supplementPassPointYn: [
            { required: true, type: 'number', message: '补充途径地必选', trigger: 'blur' },
          ],
          supplementLimitAmount: [
            { validator: validateBiggerThenZero('限制金额必须大于 0'), trigger: 'blur' },
          ],
          supplementLimitFlag: [
            { required: true, type: 'number', message: '请选择油补上限类型', trigger: 'blur' },
          ],
          approveType: [
            { required: true, type: 'number', message: '审批方式必选', trigger: 'blur' },
          ],
          customRuleYn: [
            { required: true, type: 'number', message: '自定义规则必选', trigger: 'blur' },
            { validator: validateCustomRuleYn, trigger: 'blur' },
          ],
          oilSubsidyOfflineFlag: [
            { required: true, type: 'number', message: '请选择线下加油报销', trigger: 'blur' },
          ],
        });
      }
      return basic;
    });

    const resetFields = () => {
      Object.assign(sceneState.sceneData, { ...sceneState.getDefaultRecordData() });
    };

    const onClearError = (field: string) => {
      if (field) return editorForm.value.clearValidate(field);
      return editorForm.value.clearValidate();
    };

    // = ---------------------------- = 提交表单 = ---------------------------- =
    const onSubmit = async () => {
      if (submiting.value) return;

      try {
        await editorForm.value.validate();
      } catch (e: Error & any) {
        return message.error(firstError(e));
      }

      submiting.value = true;
      const { stepSupplementLimits, qyNos, qyNames, cyNames, cyNos, trqNos, trqNames, ...others } = formData;
      const data = {
        ...others,
        prohibitOilNos: qyNos.concat(cyNos).concat(trqNos).join(','),
        prohibitOilNames: qyNames.concat(cyNames).concat(trqNames).join(','),
        quota: toApiUnit(parseNumberOrZero(formData.quota)),
        totalQuota: toApiUnit(parseNumberOrZero(formData.totalQuota)),
        expresswayQuota: toApiUnit(parseNumberOrZero(formData.expresswayQuota)),
        supplementLimit: toApiUnit(parseNumberOrZero(formData.supplementLimitAmount)),
        supplementLimitAmount: toApiUnit(parseNumberOrZero(formData.supplementLimitAmount)),
        perKilometerAmount: toApiUnit(parseNumberOrZero(formData.perKilometerAmount)),
        frequency: +formData.frequency,
        dayLimitAmountStatus: +formData.dayLimitAmountStatus,
        singleLimitAmountStatus: +formData.singleLimitAmountStatus,
        frequencyNum: +formData.frequencyNum,
        radius: parseNumberOrZero(formData.radius),
        dayLimitAmount: toApiUnit(parseNumberOrZero(formData.dayLimitAmount)),
        singleLimitAmount: toApiUnit(parseNumberOrZero(formData.singleLimitAmount)),
        stepSupplementLimits: (stepSupplementLimits || []).map(item => {
          const { startKm, endKm, supplementLimitAmount } = item;
          // 需要转化为分，展示元，存储分
          return { startKm, endKm, supplementLimitAmount: toApiUnit(parseNumberOrZero(supplementLimitAmount)) };
        }),
      };

      let res: any;
      try {
        if (actionType.value === 'create') {
          res = await request.post(createApiUrl('/newlinkSass/staff/restrict/add'), data);
        } else {
          res = await request.put(createApiUrl('/newlinkSass/staff/restrict/update'), data);
        }
        await request.post(createApiUrl('/newlinkSass/staff/restrict/save/oil/supplement/limit'), pick({ ...data, confNo: res.data?.confNo || data.confNo }, ['confNo', 'totalQuota', 'quota', 'expresswayQuota', 'supplementLimitType']));
        if (res.code === 200 && actionType.value !== 'create') {
          await sceneState.loadSceneData(recordId, recordEnterpriseId);
          const prohibitData = handleProhibit(sceneState.sceneData); 
          Object.assign(formData, { ...sceneState.sceneData, ...prohibitData });
        }
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        submiting.value = false;
      }

      if (actionType.value === 'create') {
        message.success('新建制度完成!');
        visible.value = false;
        ctx.emit('complete', res.data.confNo);
      } else {
        message.success('制度保存完成!');
        ctx.emit('complete');
        editorForm.value?.clearValidate();
        oldSceneName = formData.title;
        editBlock.value = null;
      }
    };

    const onCancel = () => {
      visible.value = false;
      editBlock.value = null;
    };

    const oilProhibitValidator = (rule:any, value:any) => {
      if (!value && value !== 0) return Promise.reject('请选择油品限制');
      if (value && !formData.qyNos.length && !formData.cyNos.length && !formData.trqNos.length) return Promise.reject('请选择支持加注的油品');
      return Promise.resolve(); 
    };

    const validateTieredPrice = (item:Record<string, any>, index:number, arr: stepSupplementItemType[], rule:any, value:any) => {
      if (!item.supplementLimitAmount) return Promise.reject('请输入金额');
      return Promise.resolve(); 
    };

    const validateTieredDistance = (item:Record<string, any>, index:number, arr: stepSupplementItemType[], rule:any, value:any) => {
      if (!item.endKm) return Promise.reject('请输入距离'); 
      if (index === 0 && item.endKm <= 0) return Promise.reject('需大于0');
      if (index > 0 && +item.endKm <= +arr[index - 1].endKm) return Promise.reject('小于起始距离');
      return Promise.resolve(); 
    };
    const changeEndKm = () => {
      const { length } = formData.stepSupplementLimits;
      for (let i = 1; i < length; i++) {
        formData.stepSupplementLimits[i].startKm = formData.stepSupplementLimits[i - 1].endKm;
      }
    };
    const removeDomain = (index:number) => {
      formData.stepSupplementLimits.splice(index, 1);
      changeEndKm();
    };
    const addDomain = (domain: stepSupplementItemType, index:number) => {
      if (!(domain.endKm && domain.supplementLimitAmount)) return message.warning(`请先填写阶梯${index + 1}信息`);
      if (formData.stepSupplementLimits.length >= 10) return message.warning('不能超过10个!');
      formData.stepSupplementLimits.splice(index + 1, 0, {
        key: Date.now(),
        startKm: '',
        endKm: '',
        supplementLimitAmount: '',
      });
    };

    const getOilList = async (type?: number) => {
      try {
        const res = await request.get(createApiUrl('/newlinkSass/enterprise/getCanUsedOilNos'), { params: { enterpriseId: formData.enterpriseId } });
        if (res.code !== 200) return message.error(res.message);
        Object.assign(oilNosList, { ...res.data });
        if (type && actionType.value === 'view') {
          const prohibitData = handleProhibit(sceneState.sceneData); 
          Object.assign(formData, { ...prohibitData });
        }
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };

    watch(() => formData.enterpriseId, () => {
      oilProhibitChange({ target: { value: 0 } });
      getOilList(1);
    });

    onMounted(() => {
      getOilList();
    });
    

    return {
      ...sceneState,

      labelCol: { span: 5 },
      wrapperCol: { span: 18, offset: 1 },
      buttonCol: { span: 19, offset: 6 },

      visible,
      actionType,
      editBlock,
      editBlockState,

      // doWith,
      onCancel,
      frequencyOptions: Object.entries(frequencyTypeMap).map(([index, item]) => ({ label: item, value: +index })),

      formData,
      oilSupplementStatus,
      rules,
      resetFields,
      gasFrequency,
      editorForm,
      onClearError,
      onSubmit,
      submiting,

      hasFormEditing,
      title,
      onSwitchEdit,

      gasFrequencySuffix,

      validateTieredPrice,
      validateTieredDistance,
      addDomain,
      removeDomain,
      changeEndKm,
      oilNosList,
      oilProhibitValidator,
      qyChange,
      cyChange,
      trqChange,
      oilProhibitChange,
    };
  },
});
