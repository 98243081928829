
import { defineComponent, reactive, ref, computed, createVNode } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
import { createApiUrl, firstError } from '@/utils/utils';
import { request } from '@/utils/request';

export default defineComponent({
  name: 'SceneRemoveStaffModal',
  emits: ['complete'],
  setup(props, ctx) {
    const route = useRoute();
    const visible = ref(false);
    const submiting = ref(false);
    const recordList = ref<any[]>([]);

    const removeRecord = (id: number) => {
      recordList.value = recordList.value.filter((item: any) => item.relId !== id);
    };

    const onSubmit = async () => {
      if (!recordList.value.length) {
        visible.value = false;
      }
      //
      if (submiting.value) return;

      submiting.value = true;
      try {
        await request.post(
          createApiUrl('/newlinkSass/vehicle/restrict/bind-vehicles-batch'),
          { restrictId: route.params.id, vehicleIds: recordList.value.map(item => item.id), type: 2 },
        );
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        submiting.value = false;
      }

      visible.value = false;
      message.success('车辆移除完成');
      ctx.emit('complete');
    };

    const remove = (list: any[]) => {
      recordList.value = list;
      visible.value = false;

      Modal.confirm({
        content: '确认批量解绑车辆？',
        icon: createVNode(ExclamationCircleFilled),
        class: 'noneFooterBorder',
        onOk() {
          onSubmit();
        },
      });
    };

    return {
      submiting,
      visible,
      recordList,

      remove,
      removeRecord,
      onSubmit,
    };
  },
});
