

import { createVNode, defineComponent, ref } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { InfoCircleFilled } from '@ant-design/icons-vue';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { useEcho } from '@/utils/echo';
import { SceneRecordType } from './utils';

export default defineComponent({
  name: 'SceneDeleteConfirm',
  emits: ['success'],
  setup(props, ctx) {
    const visible = ref(false);
    const echo = useEcho();
    const recordData = ref<SceneRecordType>({
      enterpriseId: 0,
      id: 0,
      default_flag: 0,
      carNum: 0,
      title: '',
    });

    const onSubmit = async () => {
      try {
        await request.get(createApiUrl('/newlinkSass/vehicle/restrict/delete-by-id'), {
          params: { restrictId: recordData.value.id },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      message.success('制度删除完成');
      ctx.emit('success');
      visible.value = false;
    };

    echo('del', (record: SceneRecordType) => {
      recordData.value = record;
      visible.value = false;
      let text: any = '';
      if (record.carNum) text = '制度下有绑定车辆，删除后自动绑定默认制度';

      Modal.confirm({
        title: '确认删除该制度 ？',
        icon: createVNode(InfoCircleFilled),
        content: text,
        class: 'noneFooterBorder',
        onOk() {
          onSubmit();
        },
      });
    });

    return {
      visible,
      recordData,
      onSubmit,
    };
  },
});
