
import { computed, createVNode, defineComponent, onMounted, reactive, ref } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { noEmptyProp, insert } from '@/utils/illuminate';

import { InfoCircleFilled } from '@ant-design/icons-vue';
import type { TableColumn, TableOptions } from '@/components/Table';
import { createApiUrl, sldPageTitle } from '@/utils/utils';
import { request } from '@/utils/request';
import { useState } from '@/store';

import { useScene } from '@/views/scene/utils';
import router from '@/router';
import AddStaffModal from '@/views/parts/AddStaff.vue';
import RemoveStaffModal from './RemoveStaff.vue';

export default defineComponent({
  name: 'SceneListView',
  components: {
    AddStaffModal,
    RemoveStaffModal,
  },
  setup(props, ctx) {
    const route = useRoute();
    const sceneState = useScene();
    const title = computed(() => sceneState.sceneData.title || '---');
    const filterParams = reactive({
      nameOrPhone: '',
    });

    const tableSelectedRowKeys = ref<any[]>([]);
    const tableSelectedRows = ref<any[]>([]);

    const onTableSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
      tableSelectedRowKeys.value = selectedRowKeys;
      tableSelectedRows.value = selectedRows;
    };

    const tableOptions = computed<TableOptions>(() => ({
      alert: { show: true, clear: () => { tableSelectedRowKeys.value = []; } },
      rowSelection: {
        selectedRowKeys: tableSelectedRowKeys.value,
        onChange: onTableSelectChange,
        getCheckboxProps: (record: any) => ({
          props: { name: 'check-line' },
        }),
      },
    }));

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(
        createApiUrl('/newlinkSass/staff/restrict/get/staff/list'),
        {
          params: { ...parameter, ...noEmptyProp(filterParams), confNo: route.params.id },
          timeout: 30000,
        },
      )
        .then((res: any) => {
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const addItemRef = ref<any>(null);
    const onAddItem = () => {
      addItemRef.value.add();
    };

    // = ---------------------------- = 解绑员工 = ---------------------------- =
    const submitMultipleRemove = async (userCodes: number[]) => {
      //
      try {
        await request.put(
          createApiUrl('/newlinkSass/staff/restrict/staff/unbind'),
          { confNo: route.params.id, userCodes },
        );
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      message.success('操作成功！');
      onRefreshTable();
    };

    const removeMultipleItemRef = ref<any>(null);
    const onRemoveMultipleItem = () => {
      if (!tableSelectedRows.value.length) return message.error('请选择要解绑的员工');
      removeMultipleItemRef.value.remove([...tableSelectedRows.value]);
    };

    const onDelItem = async (record: any) => {
      Modal.confirm({
        title: '确认 解绑员工 ？',
        icon: createVNode(InfoCircleFilled),
        content: '解绑后，该员工自动绑定默认制度',
        onOk() {
          submitMultipleRemove([record.userCode]);
        },
      });
    };

    const tableColumns = computed<TableColumn[]>(() => {
      const basic = [
        { title: '姓名', dataIndex: 'userName' },
        { title: '手机号码', dataIndex: 'phone' },
        { title: '部门', dataIndex: 'department' },
      ];
      if (!sceneState.sceneData.defaultFlag) insert(basic, 3, { title: '操作', dataIndex: 'action', width: '150px', slots: { customRender: 'action' } });
      return basic;
    });

    const onLoadStaffList = async (departmentId: string) => {
      const res = await request.get(
        createApiUrl('/newlinkSass/staff/restrict/get/staff/unbind/list'),
        {
          params: { confNo: route.params.id, departmentId },
          timeout: 30000,
        },
      );
      return res.data.restrictStaffDtos;
    };

    const onSubmitStaffList = (userCodes: string[]) => request.put(
      createApiUrl('/newlinkSass/staff/restrict/staff/bind'),
      { confNo:	route.params.id, userCodes },
    );

    onMounted(() => {
      sceneState.loadSceneData(route.params.id as string, route.params.enterpriseId as string);
    });

    return {
      ...sceneState,

      title,
      filterParams,
      tableColumns,
      tableRef,
      sldPageTitle,

      tableSelectedRowKeys,
      tableSelectedRows,
      tableOptions,

      loadTableData,
      onRefreshTable,

      addItemRef,
      onAddItem,

      removeMultipleItemRef,
      onRemoveMultipleItem,

      onDelItem,
      onLoadStaffList,
      onSubmitStaffList,
    };
  },
});
