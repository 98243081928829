import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "autoStaffList__content" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_app_operates = _resolveComponent("app-operates")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_add_staff_modal = _resolveComponent("add-staff-modal")!
  const _component_remove_staff_modal = _resolveComponent("remove-staff-modal")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer autoStaffList" }, {
    title: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.sldPageTitle('#1890ff', '管理员工')
      }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_form, {
          class: "fl-clean autoStaffList__filterForm",
          form: _ctx.filterParams,
          layout: 'inline'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "员工" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.filterParams.nameOrPhone,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterParams.nameOrPhone) = $event)),
                  placeholder: "员工姓名 / 手机号码",
                  "allow-clear": ""
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  ghost: "",
                  onClick: _ctx.onRefreshTable
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("查询")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["form"]),
        _createVNode(_component_app_operates, { mg: "0 0 10px 0" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              style: {"margin-right":"10px"},
              onClick: _ctx.onAddItem
            }, {
              default: _withCtx(() => [
                _createTextVNode("添加员工")
              ]),
              _: 1
            }, 8, ["onClick"]),
            (!_ctx.sceneData.defaultFlag)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  type: "primary",
                  onClick: _ctx.onRemoveMultipleItem
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("批量解绑")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.sceneData?.title === '默认制度')
          ? (_openBlock(), _createBlock(_component_app_table, {
              key: 0,
              ref: "tableRef",
              size: "default",
              "row-key": "relId",
              "show-pagination": true,
              columns: _ctx.tableColumns,
              data: _ctx.loadTableData
            }, {
              action: _withCtx(({ record }) => [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.onDelItem(record)), ["prevent"])
                }, "解绑员工", 8, _hoisted_3)
              ]),
              _: 1
            }, 8, ["columns", "data"]))
          : (_openBlock(), _createBlock(_component_app_table, {
              key: 1,
              ref: "tableRef",
              size: "default",
              "row-key": "relId",
              "show-pagination": true,
              columns: _ctx.tableColumns,
              data: _ctx.loadTableData,
              alert: _ctx.tableOptions.alert,
              "row-selection": _ctx.tableOptions.rowSelection
            }, {
              action: _withCtx(({ record }) => [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.onDelItem(record)), ["prevent"])
                }, "解绑员工", 8, _hoisted_4)
              ]),
              _: 1
            }, 8, ["columns", "data", "alert", "row-selection"]))
      ]),
      _createVNode(_component_add_staff_modal, {
        ref: "addItemRef",
        tips: "· 每个员工只有一个制度，分配新制度时会替换员工原有制度",
        "load-staff-list": _ctx.onLoadStaffList,
        "submit-user-list": _ctx.onSubmitStaffList,
        onComplete: _ctx.onRefreshTable
      }, null, 8, ["load-staff-list", "submit-user-list", "onComplete"]),
      _createVNode(_component_remove_staff_modal, {
        ref: "removeMultipleItemRef",
        onComplete: _ctx.onRefreshTable
      }, null, 8, ["onComplete"])
    ]),
    _: 1
  }))
}