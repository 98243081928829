import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { createApiUrl, toLocalUnit } from '@/utils/utils';
import { request } from '@/utils/request';
import { useState } from '@/store';
import { parseNumberOrZero } from '@/utils/illuminate';

/* eslint-disable camelcase */
export type SceneRecordType = {
  enterpriseId: number;
  id: number;
  default_flag: number;
  carNum: number;
  title: string;
}

export type stepSupplementItemType = {
  key: number | string,
  startKm: number | string,
  endKm: number | string,
  supplementLimitAmount: number | string
}

export type SceneType = {
  defaultFlag: number;
  confNo?: number; //	制度编号
  title: string; //	制度名称
  enterpriseId: number | undefined; // 企业 Id
  enterpriseName: string | undefined;
  id?: number;

  // 私车公用油补管理
  state: number; // 油补管理状态：0-关闭(默认)，1-开启
  perKilometerAmount: number | ''; // 油补标准：每公里费用（分）
  radius: number | ''; // 打卡半径（米）
  updateAddressYn: number; // 是否允许修改地址 1-是 0-否
  supplementPassPointYn: number; // 是否允许补充途径点 1-是 0-否
  // passPointConf: number; // 途经点位置：0-只允许微调，1-自定义位置
  supplementLimitAmount: number | ''; // 油补上限（分）
  approveType: number | null; // 审批方式 1-人工加自动 2-自动审批 3-人工审批
  customRuleYn: number; // 是否有自定义规则 1-是 0-否
  customTitle: string; // 自定义规则标题
  customContent: string; // 自定义标题内容
  calculationMethod: number; // 油补方式
  stepSupplementLimits: stepSupplementItemType[],
  allowDeclaredMile: boolean, // 是否允许申报行驶里程
  mustUploadProof?: boolean, // 是否强制上传举证图片
  oilSubsidyOfflineFlag?: number, // 是否允许线下报销
};


export function useScene() {
  const store = useStore();
  const state = useState();
  // = ---------------------------- = 加载制度列表 作为表单 = ---------------------------- =
  const sceneList = ref([]);


  const defaultStepSupplementLimits: stepSupplementItemType[] = [
    { key: Date.now(), startKm: 0, endKm: '', supplementLimitAmount: '' },
    { key: Date.now() + 1, startKm: '', endKm: '', supplementLimitAmount: '' },
  ];
  const getDefaultRecordData = (): SceneType => ({
    confNo: 0,
    defaultFlag: 0,
    title: '',
    enterpriseId: state.enterprise?.value.enterpriseId,
    enterpriseName: state.enterprise?.value.enterpriseName,

    state: 0,
    perKilometerAmount: '',
    radius: 500,
    updateAddressYn: 1, // 修改地址默认选择允许
    supplementPassPointYn: 1,
    // passPointConf: 0,
    supplementLimitAmount: '',
    approveType: 1,
    customRuleYn: 0,
    customTitle: '',
    customContent: '',

    calculationMethod: 0,
    stepSupplementLimits: defaultStepSupplementLimits,
    allowDeclaredMile: false,
    mustUploadProof: true,
    oilSubsidyOfflineFlag: 0,
  });

  // = ---------------------------- = 加载制度详情 = ---------------------------- =
  const sceneData = reactive({
    ...getDefaultRecordData(),
  });

  const loadSceneData = async (id: number, enterpriseId?: string) => {
    let res: any;
    try {
      res = await request.get(createApiUrl('/newlinkSass/vehicle/restrict/get-by-id'), {
        params: { restrictId: id, enterpriseId },
      });
    } catch (e: Error & any) {
      return message.error(e.message);
    }
    const { stepSupplementLimits } = res.data;
    Object.assign(sceneData, {
      ...res.data,
      perKilometerAmount: toLocalUnit(res.data.perKilometerAmount || 0), // 油补标准：每公里费用（分）
      supplementLimitAmount: toLocalUnit(res.data.supplementLimitAmount || 0), // 油补上限（分）
      stepSupplementLimits: Array.isArray(stepSupplementLimits) && stepSupplementLimits.length ? stepSupplementLimits.map((item: stepSupplementItemType) => {
        item.supplementLimitAmount = toLocalUnit(parseNumberOrZero(item.supplementLimitAmount || 0));
        return item;
      }) : defaultStepSupplementLimits,
    });
  };

  return {
    getDefaultRecordData,
    sceneList,

    sceneData,
    loadSceneData,
  };
}
